@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main: #93C5FD;
    --main-light: #E3F0FF;
    --white: #ffffff;
    --black: #000000;
    --purple: #C5ADEB;
    --light-purple: #e2d6f5;
    --green: #9AEDB6;
    --orange: #FDC474;
}

h1 {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h2 {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h3 {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h4 {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h5 {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

p {
    color: #000;
    font-family: Mochiy Pop One;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

span {
    font-family: Mochiy Pop One;
}

/* Reset some default styles */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;   
    max-height: 100vh;
    color: var(--black);
}

.orange-input {
    font-family: Mochiy Pop One;
    width: 100%;
    height: auto;
    border-bottom: 3px solid var(--orange);
}

.text-blue-300 {
    color: #93c5fd
}

.bg-red-300 {
    background: #fca5a5;
}

:focus-visible {
    outline: none;
}

.bg-white {
    background-color: var(--white);
}

.bg-purple {
    background-color: var(--purple);
}

.bg-light-purple {
    background-color: var(--light-purple);
}

.fill-purple {
    fill: var(--purple);
}

.text-purple {
    color: var(--purple);
}

.break-word {
    overflow-wrap: break-word;
    white-space: normal;
}

.button-orange {
    border-radius: .5rem;
    background-color: var(--orange);
}

.tooltip {
    @apply invisible absolute;
}
  
.has-tooltip:hover .tooltip {
    @apply visible z-50;
}

@media only screen and (max-width: 640px) {
    h1 {
        font-size: 2.2rem;     
    }
    
    h2 {
        font-size: 1.7rem;
    }
    
    h3 {
        font-size: 1.4rem;
    }
    
    h4 {
        font-size: 1.1rem;
    }

    h5 {
        font-size: 1rem;
    }
    
    p {
        font-size: .9rem;
    }
  }

@keyframes fadeAway {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
.fadeAway {
    animation-duration: 3.5s;
    animation-name: fadeAway;
    opacity: 0;
}